import { DateTime } from 'luxon';
import { Body, Equator, Observer, Horizon } from 'astronomy-engine';
import { assertIsNotNil } from '../../lukeLibUtils/assert';
import { saveToDomWindow } from '../../lukeLibUtils/react/saveToDomWindow';

/*
TODOs:

- Consider jumping 24 hours + 5 mins for each frame so that we see elements of the fixed passage AND the day/night cycle
- Interpolate between frames to allow for slower and smoother animation
- Radiant halo around the sun
- Pan around with mouse or finger
- Zoom in and out with mouse or pinch
- Click on a planet to see more info
- UI framework for the UI bits

- Planets in 3D but with grainy renderer? (like sort of PS1 almost horroristic? Maybe some different modes)
- Maybe colourful purposeful constellations, like Sushi!
- Some kind of "about" info

 */

// setupMinimizeButton();
setupStars();
// setupPlanetSimulation();

type Planet = {
  id: string;
  body: Body;
  elem: HTMLElement;
};

function setupPlanetSimulation() {
  const background = assertIsNotNil(document.getElementById('the-background'));
  const londonObserver = new Observer(51.5072, -0.1276, 24);

  const getBodyCoords = (body: Body, now: DateTime) => {
    const jsDate = now.toUTC().toJSDate();
    const equatorialPos = Equator(body, jsDate, londonObserver, true, true);
    const horizonPos = Horizon(
      jsDate,
      londonObserver,
      equatorialPos.ra,
      equatorialPos.dec,
      'normal'
    );
    return { equatorialPos, horizonPos };
  };

  // TODO3 if close to the edge, render on both sides!
  // TODO3 or, instead, a way to pan around?
  const renderBodyAsElement = (
    elem: HTMLElement,
    coords: ReturnType<typeof getBodyCoords>
  ) => {
    // docs on altitude:
    // > The value is in the range [-90, +90]
    elem.style.top = `${((coords.horizonPos.altitude + 90) / 180) * 100}%`;
    // docs on azimuth:
    // > The value is in the range [0, 360). North = 0, east = 90, south = 180, west = 270
    // And we are facing North
    const xCoordOutOf360 = (coords.horizonPos.azimuth + 180) % 360;
    elem.style.left = `${(xCoordOutOf360 / 360) * 100}%`;
  };

  const planets: Planet[] = [];

  function setupPlanet(id: string, body: Body, sizePx: number, color: string) {
    const initCoords = getBodyCoords(body, midnightToday);
    const elem = document.createElement('div');
    elem.className = 'planet';
    elem.style.width = `${sizePx}px`;
    elem.style.height = `${sizePx}px`;
    elem.style.backgroundColor = color;
    renderBodyAsElement(elem, initCoords);
    background.appendChild(elem);
    planets.push({ id, body, elem });
  }

  const midnightToday = DateTime.now().toUTC();
  setupPlanet('sun', Body.Sun, 100, '#fdff86');
  // obviously you'll never see these two because we can't see the sun
  setupPlanet('mercury', Body.Mercury, 5, '#d2d2d2');
  setupPlanet('venus', Body.Venus, 20, '#f8e2b0');
  // setupPlanet('moon', Body.Moon, 50, 'gray');
  setupPlanet('mars', Body.Mars, 10, '#c1440e');
  setupPlanet('jupiter', Body.Jupiter, 30, '#a59186');
  setupPlanet('saturn', Body.Saturn, 20, '#e3e0c0');
  setupPlanet('uranus', Body.Uranus, 10, '#e1eeee');
  setupPlanet('neptune', Body.Neptune, 10, '#3f54ba');
  setupPlanet('pluto', Body.Pluto, 5, '#66574e');

  let currentTime = midnightToday;
  setInterval(() => {
    currentTime = currentTime.plus({ days: 1 });
    // currentTime = currentTime.plus({ hours: 1 });
    const state = {
      currentTime: currentTime.toISO(),
      coords: {} as Record<string, ReturnType<typeof getBodyCoords>>,
    };
    for (const planet of planets) {
      const coords = getBodyCoords(planet.body, currentTime);
      renderBodyAsElement(planet.elem, coords);
      state.coords[planet.id] = coords;
    }
    saveToDomWindow(['state'], state);
  }, 50);
}

function setupStars() {
  function createStar() {
    const star = document.createElement('div');
    star.className = 'star';
    star.classList.add(`star-size-${Math.floor(Math.random() * 3) + 1}`);
    star.classList.add(`star-delay-${Math.floor(Math.random() * 3) + 1}`);
    star.classList.add(`star-color-${Math.floor(Math.random() * 5) + 1}`);
    star.style.left = `${Math.random() * 100}%`;
    star.style.top = `${Math.random() * 100}%`;
    return star;
  }

  const background = assertIsNotNil(document.getElementById('the-background'));
  for (let i = 0; i < 250; i++) {
    const star = createStar();
    background.appendChild(star);
  }
}

function setupMinimizeButton() {
  const theContent = assertIsNotNil(document.getElementById('the-content'));
  const minimizeBtn = assertIsNotNil(document.getElementById('minimize-btn'));

  minimizeBtn.addEventListener('click', () => {
    theContent.classList.remove('init');
    const isMinimized = theContent.classList.toggle('minimized');
    minimizeBtn.textContent = isMinimized ? '+' : '-';
  });
}
