export function assertIsNotNil<T>(
  value: T | null | undefined,
  message?: string
): T {
  if (value === null || value === undefined) {
    // eslint-disable-next-line no-debugger
    debugger;
    throw new Error(message || `Value is nil. Value: ${value}`);
  }
  return value;
}

export function assertIsNonEmptyArray<T>(value: T[]): value is [T, ...T[]] {
  return value.length >= 1;
}
